@import url(https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@100;200;300;400;500;600;700;900&family=Open+Sans:wght@300;400;500;600;700;800&display=swap);
:root {
  /* Menta colors */
  --menta-accent: rgb(7, 39, 45);

  --menta-lila: rgb(227, 223, 248);
  --menta-violet: rgb(144,151,253);

  --menta-brown: rgb(219,220,182);
  --menta-beige: rgb(251, 246, 242);

  --menta-yellow: rgb(247, 247, 160);
  --menta-coral: rgb(239, 142, 133);
  /* End of Menta colors */


  --ql-color-blending-lighten1: 0.65;

  --ql-color-accent1: var(--menta-accent);
  --ql-color-accent1-t-lighten1: rgb(7, 39, 45, var(--ql-color-blending-lighten1));
  --ql-color-accent1-t-lighten2: rgb(7, 39, 45, var(--ql-color-blending-lighten2));
  --ql-color-accent1-t-lighten3: rgb(7, 39, 45, var(--ql-color-blending-lighten3));
  --ql-color-accent1-t-lighten4: rgb(7, 39, 45, var(--ql-color-blending-lighten4));
  --ql-color-accent1-t-lighten5: rgb(7, 39, 45, var(--ql-color-blending-lighten5));
  --ql-color-accent1-t-lighten6: rgb(7, 39, 45, var(--ql-color-blending-lighten6));

  --ql-color-dark: rgb(3, 11, 23);
  --ql-color-dark-t-lighten1: rgb(3, 11, 23, var(--ql-color-blending-lighten1));
  --ql-color-dark-t-lighten2: rgb(3, 11, 23, var(--ql-color-blending-lighten2));
  --ql-color-dark-t-lighten3: rgb(3, 11, 23, var(--ql-color-blending-lighten3));
  --ql-color-dark-t-lighten4: rgb(3, 11, 23, var(--ql-color-blending-lighten4));
  --ql-color-dark-t-lighten5: rgb(3, 11, 23, var(--ql-color-blending-lighten5));
  --ql-color-dark-t-lighten6: rgb(3, 11, 23, var(--ql-color-blending-lighten6));

  --ql-color-danger: var(--menta-coral);
  --ql-color-danger-t-lighten1: rgb(239, 142, 133, var(--ql-color-blending-lighten1));
  --ql-color-danger-t-lighten2: rgb(239, 142, 133, var(--ql-color-blending-lighten2));
  --ql-color-danger-t-lighten3: rgb(239, 142, 133, var(--ql-color-blending-lighten3));
  --ql-color-danger-t-lighten4: rgb(239, 142, 133, var(--ql-color-blending-lighten4));
  --ql-color-danger-t-lighten5: rgb(239, 142, 133, var(--ql-color-blending-lighten5));
  --ql-color-danger-t-lighten6: rgb(239, 142, 133, var(--ql-color-blending-lighten6));

  --ql-color-success: rgb(82, 196, 26);
  --ql-color-success-t-lighten1: rgb(82, 196, 26, var(--ql-color-blending-lighten1));
  --ql-color-success-t-lighten2: rgb(82, 196, 26, var(--ql-color-blending-lighten2));
  --ql-color-success-t-lighten3: rgb(82, 196, 26, var(--ql-color-blending-lighten3));
  --ql-color-success-t-lighten4: rgb(82, 196, 26, var(--ql-color-blending-lighten4));
  --ql-color-success-t-lighten5: rgb(82, 196, 26, var(--ql-color-blending-lighten5));
  --ql-color-success-t-lighten6: rgb(82, 196, 26, var(--ql-color-blending-lighten6));

  --ql-color-warning: rgb(250, 173, 20);
  --ql-color-warning-t-lighten1: rgb(250, 173, 20, var(--ql-color-blending-lighten1));
  --ql-color-warning-t-lighten2: rgb(250, 173, 20, var(--ql-color-blending-lighten2));
  --ql-color-warning-t-lighten3: rgb(250, 173, 20, var(--ql-color-blending-lighten3));
  --ql-color-warning-t-lighten4: rgb(250, 173, 20, var(--ql-color-blending-lighten4));
  --ql-color-warning-t-lighten5: rgb(250, 173, 20, var(--ql-color-blending-lighten5));
  --ql-color-warning-t-lighten6: rgb(250, 173, 20, var(--ql-color-blending-lighten6));

  --ql-color-info: rgb(7, 39, 45);
  --ql-color-info-t-lighten2: rgb(7, 39, 45, var(--ql-color-blending-lighten2));
  --ql-color-info-t-lighten1: rgb(7, 39, 45, var(--ql-color-blending-lighten1));
  --ql-color-info-t-lighten3: rgb(7, 39, 45, var(--ql-color-blending-lighten3));
  --ql-color-info-t-lighten4: rgb(7, 39, 45, var(--ql-color-blending-lighten4));
  --ql-color-info-t-lighten5: rgb(7, 39, 45, var(--ql-color-blending-lighten5));
  --ql-color-info-t-lighten6: rgb(7, 39, 45, var(--ql-color-blending-lighten6));

  --ql-form-message-color: var(--ql-color-danger);
  

  /* Account */
  --ql-account-color: var(--ql-color-white);
  --ql-account-color-hover: var(--ql-color-white);

  --ql-account-title-color: var(--ql-account-color);
  --ql-account-title-font-weight: var(--ql-font-weight-medium);

  --ql-account-caption-color: var(--ql-color-white-t-lighten1);
  --ql-account-caption-font-weight: var(--ql-font-weight-medium);
  /* Account */


  --ql-font-family-main: 'Open Sans', sans-serif;
  --ql-font-family-headings: 'Montserrat Alternates', sans-serif;

  /* Outline */
  --ql-outline-color-danger:  var(--ql-color-danger-t-lighten4);

  /* Layout */
  --ql-general-layout-px-xxs: 16px; /* mobile (portrait): 0 - 576px */
  --ql-aside-collapsed-width: 0px;
  --ql-layout-background: var(--ql-color-dark-t-lighten6);

  /* Aside */
  --ql-aside-background: var(--ql-color-white);
  --ql-aside-padding: 24px;

  /* Button */
  --btn-border-color: var(--ql-color-dark-t-lighten4);

  --btn-default-color: var(--ql-typography-text-color-secondary);
  --btn-default-hover-bg: transparent;
  --btn-default-hover-color: var(--ql-color-accent1);

  --btn-text-hover-bg: var(--ql-color-dark-t-lighten5);
  --btn-text-hover-color: var(--ql-color-dark);

  /* Dropdown */
  --dropdown-item-default-bg-hover: var(--ql-color-dark-t-lighten5);
  --dropdown-item-danger-bg-hover: var(--ql-color-danger-t-lighten5);

  /* Menu */
  --ql-menu-group-title-font-weight: var(--ql-font-weight-semibold);
  --ql-menu-group-title-color: var(--ql-color-dark-t-lighten2);
  --ql-menu-group-title-padding: 8px 24px;
  --menu-dark-selected-item-icon-color: var(--ql-color-accent1);

  --menu-dark-item-hover-bg: var(--ql-color-white-t-lighten5);

  --menu-dark-item-active-bg: var(--menta-yellow);
  --menu-dark-selected-item-text-color: var(--ql-color-accent1);
  --menu-dark-selected-item-icon-color: var(--ql-color-accent1);
  /* Menu —————————————————————————————— End */


  /* Account */
  --ql-account-avatar-box-shadow-hover: inset 0 0 0 2px var(--ql-color-accent1);
}
.firebaseui-container {
  margin: 0 !important;
  max-width: none !important;
}
.firebaseui-card-content, .firebaseui-card-footer {
  padding: 0 !important;
}
.firebaseui-idp-list, .firebaseui-tenant-list {
  margin: 0 !important;
}
.firebaseui-idp-list>.firebaseui-list-item, .firebaseui-tenant-list>.firebaseui-list-item {
  margin-bottom: 0 !important;
}
.firebaseui-idp-button, .firebaseui-tenant-button {
  max-width: none !important;
  height: var(--ql-component-height-lg) !important;
}
.mdl-button {
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-radius: var(--btn-border-radius-lg) !important;
  transition: box-shadow .2s cubic-bezier(.4,0,1,1),background-color .2s cubic-bezier(.4,0,.2,1),color .2s cubic-bezier(.4,0,.2,1);
}
.firebaseui-idp-icon {
  height: 24px !important;
  width: 24px !important;
}
.firebaseui-idp-text {
  font-size: var(--btn-font-size-lg) !important;
  line-height: var(--ql-typography-text-line-height-lg);
  padding-left: 16px !important;
}
.firebaseui-idp-google>.firebaseui-idp-text {
  color: var(--btn-white-color) !important;
  transition: color .2s cubic-bezier(.4,0,.2,1);
}
.mdl-button--raised:hover {
  box-shadow: 0 6px 8px 0 rgb(0 0 0 / 4%), 0 8px 8px -2px rgb(0 0 0 / 8%), 0 16px 48px -16px rgb(0 0 0 / 12%) !important;
}
.mdl-button:hover.firebaseui-idp-google>.firebaseui-idp-text {
  color: var(--ql-typography-text-color-primary) !important;
  transition: color .2s cubic-bezier(.4,0,.2,1);
}
#root {
  height: 100%;
}


/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--ql-color-dark-t-lighten3);
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--ql-color-dark-t-lighten4);
}
.ce-block__content,
.ce-toolbar__content {
    max-width: calc(100% )
}
.delimiter-wrapper {
  padding: 32px 0 32px 0;
}
.delimiter {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  line-height: 1.6em;
}
.delimiter-wrapper {
  padding: 32px 0 32px 0;
}
.delimiter {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  line-height: 1.6em;
}
